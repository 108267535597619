import "./firebase"
import "./initLang";
import { initSentry } from "./initSentry";
import { initDayjs } from "./initDayjs";
import { enableMapSet } from "immer";

export function initApplication() {
    initSentry()
    enableMapSet()
    initDayjs()
}