import clsx from "clsx";
import { HTMLMotionProps, motion } from "framer-motion";
import { forwardRef, useState } from "react";

export function Image({ className, fallbackSrc, src, ...props }: HTMLMotionProps<"img"> & { fallbackSrc?: string }) {
    const [error, setError] = useState(false);
    return <motion.img src={(error || !src) ? fallbackSrc : src} className={clsx(className, { "hidden": error && !fallbackSrc })} {...props} onError={() => setError(true)} loading="lazy" initial={{ opacity: 0 }} animate={{ opacity: 1 }} />
}

export const ImageWithZoom = forwardRef<HTMLImageElement, HTMLMotionProps<"img"> & { containerClassName?: string, fallbackSrc?: string }>(({ containerClassName, className, src, fallbackSrc, ...props }, ref) => {
    const [error, setError] = useState(false);
    error && console.log(error, src, fallbackSrc)
    return <div className={clsx("overflow-hidden transition-all aspect-4/3 rounded-2xl", { "hidden": error && !fallbackSrc }, containerClassName)}><motion.img src={(error || !src) ? fallbackSrc : src} ref={ref} onError={(err) => { console.log(err); setError(true) }} className={clsx("object-cover rounded-2xl aspect-4/3 w-full", className)} {...props} loading="lazy" initial={{ opacity: 0 }} animate={{ opacity: 1 }} /></div>
})


