import { motion } from 'framer-motion'
import { forwardRef, type ReactNode } from 'react'
import { item } from './data'
import { Subtitle } from 'ui/text/Subtitle'
import { Text } from 'ui/text/Text'

interface BenefitItemProps { icon: ReactNode, title: string, description: string, iconColor: string }

export const BenefitItem = forwardRef<HTMLDivElement, BenefitItemProps>(({ icon, title, description, iconColor }, forwardedRef) => {
    return <motion.div className='grid' variants={item} ref={forwardedRef}>
        <div className='mb-4' >
            <div className='flex rounded-full w-10 h-10 p-2 justify-center items-center' style={{ backgroundColor: iconColor }}>
                {icon}
            </div>
        </div>
        <Subtitle className='text-gray-800 mb-4 h-14' >{title}</Subtitle>
        <Text className='text-gray-700' >
            {description}
        </Text>
    </motion.div>
})

BenefitItem.displayName = 'BenefitItem'
