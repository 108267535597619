import { motion } from "framer-motion"
import { useState, useMemo } from "react"
import { MoneyIcon } from "ui/icon/icons/Money"
import { PaymentsIcon } from "ui/icon/icons/Payments"
import { PercentIcon } from "ui/icon/icons/Percent"
import { Text } from "ui/text/Text"
import { Title } from "ui/text/Title"
import { BenefitItem } from "web/BenefitItem"
import { JeanVideo } from "web/JeanVideo"
import { MagicCard } from "web/MagicCard"
import { NavMenu } from "web/Navmenu"
import { Section } from "web/Section"
import { StartNowAction } from "web/StartNowAction"
import { container } from "web/data"
import "./webroot.css"


export function WebRootPage() {
    const [selectedProduct, setSelectedProduct] = useState<string>()

    const darkColor = useMemo(() => {
        if (selectedProduct === 'JeanPay') {
            return '#eebfe4'
        } else if (selectedProduct === 'JeanOrder') {
            return '#b9ddd9'
        } else if (selectedProduct === 'JeanMenu') {
            return '#dbdbdb'
        }
        return '#EEEDEF'
    }, [selectedProduct])

    const selectProduct = (product: string) => () => {
        setSelectedProduct(product)
        window.location.href = '#how_it_works'
    }

    return (
        <div className="bg-[#F7F6F7] overflow-x-hidden">
            <NavMenu />
            <div>
                <div className="bg-[#EEEDEF]" id="why_jean">
                    <div className="h-12"></div>
                    <Section
                        gap={2}
                        otherComponent={<JeanVideo />}
                        title="Self-serve ordering for restaurants"
                        action={<StartNowAction color="#028A7D" />}
                    >
                        Empower your customers to order seamlessly through QR codes, prioritizing safety and efficiency while enhancing their overall dining journey.
                    </Section>
                </div>
                <div id="products" className="py-16 flex justify-center items-center flex-col max-w-[1110px] m-auto">
                    <div>
                        <Title fontWeight="font-bold" className="text-3xl">Products</Title>
                        <Text className="text-gray-700 mb-12" >
                            Click on a product to learn more
                        </Text>
                        <motion.section className="flex max-lg:flex-col lg:flex-row gap-16" initial="hidden" whileInView="show" >
                            <MagicCard onClick={selectProduct('JeanMenu')} selected={selectedProduct === 'JeanMenu'} title="JeanMenu" tier="basic" subtitle='Revolutionize dining with our QR menus, offering a seamless and interactive way to explore culinary delights. Scan, discover, and savor the experience like never before!' />
                            <MagicCard onClick={selectProduct('JeanOrder')} selected={selectedProduct === 'JeanOrder'} title="JeanOrder" tier="fancy" subtitle='Transform your restaurant service with our QR-based ordering, ensuring a personalized and modern dining journey. Ehance customer satisfaction with effortless ordering at your restaurant.' />
                            <MagicCard onClick={selectProduct('JeanPay')} selected={selectedProduct === 'JeanPay'} title="JeanPay" tier="extraFancy" subtitle='Streamline your operations, delight customers, and seamlessly handle transactions - all in one innovative, hassle-free solution.' />
                        </motion.section>
                    </div>
                </div>
                <div id="how_it_works" style={{ backgroundColor: darkColor }} className="max-w-[1110px] m-auto p-12">
                    <Section
                        imageBorder
                        title="Step 1: Create your menu"
                        align='flex-start'
                        imageSrc="/adminMenu.png"
                    >
                        Our admin page offers a simple interface to create your menu. You can add items, categories, and customize your menu to your liking.
                    </Section>

                    <Section
                        imageSrc="/qr.jpg"
                        title="Step 2: Print your QR codes"
                        imageBorder
                        imagePosition='left'
                    >
                        Each QR code is uniquely mapped to one of your tables.
                    </Section>
                    <Section
                        otherComponent={null}
                        title="Step 3: Diners scan QR codes
            to open your menu"
                        imageSrc="/menu.png"
                        imageBorder
                    >
                        {(!selectedProduct || selectedProduct === 'JeanMenu') && 'Guests can browse your menu, and see the price and details of each item.'}
                        {selectedProduct === 'JeanOrder' && 'Guests can order, add notes and keep ordering on the same tab.'}
                        {selectedProduct === 'JeanPay' && 'Guests can order, add notes, and pay immediately.'}
                    </Section>
                    {['JeanOrder', 'JeanPay'].includes(selectedProduct || '') && <Section
                        otherComponent={null}
                        title={selectedProduct === 'JeanOrder' ? 'Step 4 : Orders go to the admin app' : 'Step 4: Orders go to the admin app and payment to your account.'}
                        imagePosition='left'
                        imageBorder
                        imageSrc="/dashboard.png"
                    >
                        Your staff can prep and run orders, guests can track the status of their orders.
                    </Section>}
                </div>
                <div id="smart_checkout">
                    <Section title="Accept payments online with Jean" subtitle='Smart Checkout by Viva Wallet' imagePosition='left' imageSrc='/smart_checkout.png'>Smart Checkout is a checkout solution that gets better
                        and smarter over time. It is fast to integrate, offers
                        increased conversion, and is continually self-updated.
                        As Smart Checkout is a hosted payment page, your
                        customers make their payments on a secure payment
                        page hosted by Viva Wallet.</Section>
                </div>
                <div className="p-12 pb-40 flex justify-center items-center bg-[#EEEDEF]">
                    <motion.div className="gap-8 max-w-[1110px] max-md:grid-cols-1 max-md:grid-rows-3 grid grid-cols-[repeat(3,1fr)] grid-rows-[4rem_auto_auto] md:grid-flow-row" variants={container} initial="hidden"
                        whileInView="show" >
                        <BenefitItem iconColor='#8d3677' icon={<PaymentsIcon size="fill" className="fill-white" />} title="Online payment package" description="Business account with local IBAN and next day settlement. Advanced reporting and management of you business costs. Multiple payment methods." />
                        <BenefitItem iconColor='#1f2937' icon={<MoneyIcon size="fill" className="fill-white" />} title="Daily Pay-outs" description="Your online sales are cleared daily into your Business Account. Access your funds and be able to make payments, or transfer funds to any bank account." />
                        <BenefitItem iconColor='#028a7D' icon={<PercentIcon size="fill" className="fill-white" />} title="Transparent prices" description="The payment acceptance fee is set by Viva Wallet. Plus an extra fee per transaction by Jean." />
                    </motion.div>
                </div>
            </div>
        </div>)
}