// @ts-nocheck
export class ConsoleMock implements Console {
    Console: NodeJS.ConsoleConstructor;
    constructor() {
        this.Console = ConsoleMock;
    }

    assert(condition?: boolean, ...data: any[]): void;
    assert(condition?: boolean, ...data: any[]): void;
    assert(value: any, message?: string, ...optionalParams: any[]): void;
    assert(value?: any, message?: any, ...optionalParams: any[]): void { }
    clear(): void;
    clear(): void;
    clear(): void;
    clear(): void { }
    count(label?: string): void;
    count(label?: string): void;
    count(label?: string): void;
    count(label?: any): void { }
    countReset(label?: string): void;
    countReset(label?: string): void;
    countReset(label?: string): void;
    countReset(label?: any): void { }
    debug(...data: any[]): void;
    debug(...data: any[]): void;
    debug(message?: any, ...optionalParams: any[]): void;
    debug(message?: any, ...optionalParams: any[]): void { }
    dir(item?: any, options?: any): void;
    dir(item?: any, options?: any): void;
    dir(obj: any, options?: NodeJS.InspectOptions): void;
    dir(obj?: any, options?: any): void { }
    dirxml(...data: any[]): void;
    dirxml(...data: any[]): void;
    dirxml(...data: any[]): void;
    dirxml(...data: any[]): void { }
    error(...data: any[]): void;
    error(...data: any[]): void;
    error(message?: any, ...optionalParams: any[]): void { }
    group(...data: any[]): void;
    group(...data: any[]): void;
    group(...label: any[]): void;
    group(...label: any[]): void { }
    groupCollapsed(...data: any[]): void;
    groupCollapsed(...data: any[]): void;
    groupCollapsed(...label: any[]): void;
    groupCollapsed(...label: any[]): void { }
    groupEnd(): void;
    groupEnd(): void;
    groupEnd(): void;
    groupEnd(): void { }
    info(...data: any[]): void;
    info(...data: any[]): void;
    info(message?: any, ...optionalParams: any[]): void;
    info(message?: any, ...optionalParams: any[]): void { }
    log(...data: any[]): void;
    log(...data: any[]): void;
    log(message?: any, ...optionalParams: any[]): void;
    log(message?: any, ...optionalParams: any[]): void { }
    table(tabularData?: any, properties?: string[]): void;
    table(tabularData?: any, properties?: string[]): void;
    table(tabularData: any, properties?: readonly string[]): void;
    table(tabularData?: any, properties?: any): void { }
    time(label?: string): void;
    time(label?: string): void;
    time(label?: string): void;
    time(label?: any): void { }
    timeEnd(label?: string): void;
    timeEnd(label?: string): void;
    timeEnd(label?: string): void;
    timeEnd(label?: any): void { }
    timeLog(label?: string, ...data: any[]): void;
    timeLog(label?: string, ...data: any[]): void;
    timeLog(label?: string, ...data: any[]): void;
    timeLog(label?: any, ...data: any[]): void { }
    timeStamp(label?: string): void;
    timeStamp(label?: string): void;
    timeStamp(label?: string): void;
    timeStamp(label?: any): void { }
    trace(...data: any[]): void;
    trace(...data: any[]): void;
    trace(message?: any, ...optionalParams: any[]): void;
    trace(message?: any, ...optionalParams: any[]): void { }
    warn(...data: any[]): void;
    warn(...data: any[]): void;
    warn(message?: any, ...optionalParams: any[]): void { }
    markTimeline(label?: string): void { }
    profile(label?: string): void { }
    profileEnd(label?: string): void { }
    timeline(label?: string): void { }
    timelineEnd(label?: string): void { }
}
