import clsx from "clsx";
import { ButtonHTMLAttributes, DetailedHTMLProps, PropsWithChildren } from "react";

interface TextProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement> {
  variant?: "body" | "tag" | "price" | "description";
  color?: "inherit" | "gray";
  testid?: string
}

export function Text({ variant = "body", className, children, color = "inherit", testid, ...pProps }: PropsWithChildren<TextProps>) {
  const classes = clsx(className, "font-body", {
    "text-sm font-regular": variant === "body",
    "text-sm uppercase font-medium tracking-wider": variant === "tag",
    "text-sm font-medium": variant === "price",
    "text-xs font-nav  font-light leading-4": variant === "description",
    "text-secondary-950": color === "gray",
    "text-inherit": color === "inherit"
  })
  return <p className={classes} data-testid={testid} {...pProps}>{children}</p>;
}
