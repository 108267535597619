import { initializeApp } from 'firebase/app';
import { getMessaging } from "firebase/messaging";
import { getAnalytics, logEvent, setAnalyticsCollectionEnabled } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth"
import { getStorage, connectStorageEmulator } from "firebase/storage"
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";


export const firebaseConfig = {
    apiKey: import.meta.env.VITE_APP_API_KEY,
    authDomain: import.meta.env.VITE_APP_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_APP_PROJECT_ID,
    storageBucket: import.meta.env.VITE_APP_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_APP_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_APP_APP_ID,
    measurementId: import.meta.env.VITE_APP_MEASUREMENT_ID
}

const app = initializeApp(firebaseConfig);
initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(import.meta.env.VITE_APP_SITE_KEY),
    isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});

export const storage = getStorage(app)
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const analytics = getAnalytics(app);
export const messaging = getMessaging(app);
setAnalyticsCollectionEnabled(analytics, import.meta.env.PROD);
logEvent(analytics, 'initialized');
if (window.location.hostname === 'localhost' && import.meta.env.DEV) {
    console.log('Using emulators')
    connectAuthEmulator(auth, 'http://localhost:9099')
    connectStorageEmulator(storage, 'localhost', 9199)
    connectFirestoreEmulator(firestore, "localhost", 8080)
}
