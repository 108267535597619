import { motion } from 'framer-motion'
import { CSSProperties, forwardRef, useMemo } from 'react'
import { OrdersIcon } from 'ui/icon/icons/Orders'
import { PaymentIcon } from 'ui/icon/icons/Payment'
import { QrIcon } from 'ui/icon/icons/Qr'

interface MagicCardProps {
  title: string
  subtitle: string
  tier: 'basic' | 'fancy' | 'extraFancy'
  selected?: boolean
  onClick?: () => void
}
export const MagicCard = forwardRef<HTMLDivElement, MagicCardProps>(({ title, tier, subtitle, selected, onClick }, ref) => {
  const darkColor = useMemo(() => {
    if (tier === 'basic') {
      return '#dbdbdb'
    } else if (tier === 'fancy') {
      return '#b9ddd9'
    } else {
      return '#eebfe4'
    }
  }, [tier])

  const darkAltColor = useMemo(() => {
    if (tier === 'basic') {
      return '#374151'
    } else if (tier === 'fancy') {
      return '#003832'
    } else {
      return '#49133d'
    }
  }, [tier])

  const whiteColor = useMemo(() => {
    if (tier === 'basic') {
      return '#1f2937'
    } else if (tier === 'fancy') {
      return '#003d36'
    } else {
      return '#350c2a'
    }
  }, [tier])

  const transition = '0.5s ease-in-out'

  const color = useMemo(() => {
    if (tier === 'basic') {
      return '#273041'
    } else if (tier === 'fancy') {
      return '#028A7D'
    } else {
      return '#8d3677'
    }
  }, [tier])

  const selectedIcon = {
    color: darkColor,
    backgroundColor: color,
    boxShadow: `0 0 0 4px ${darkColor}, 0 0 0 300px ${color}`
  }

  const selectedP = {
    color: darkColor,
    transition
  }

  const selectedH3 = {
    color: darkColor,
    transition
  }

  const cardStyles: CSSProperties = {
    cursor: 'pointer',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'translate(0)',
    background: darkColor,
    transition,
    fontFamily: 'inherit',
    height: '350px',
    width: '300px',
    display: 'flex'
  }

  const iconStyles: CSSProperties = {
    overflow: 'hidden',
    height: '80px',
    width: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '3rem',
    color,
    backgroundColor: darkColor,
    transition,
    boxShadow: `0 0 0 4px ${darkColor}, 0 0 0 6px ${color}`,
    ...(selected ? selectedIcon : {})
  }

  const h3Styles: CSSProperties = {
    fontSize: '1.5rem',
    fontWeight: 500,
    color: selected ? darkAltColor : whiteColor,
    transition,
    ...(selected ? selectedH3 : {})
  }

  const pStyles: CSSProperties = {
    fontSize: '0.9rem',
    height: '130px',
    color: darkAltColor,
    transition,
    ...(selected ? selectedP : {})
  }
  const Icon = tier === 'basic' ? QrIcon : tier === 'fancy' ? OrdersIcon : PaymentIcon

  return <motion.div whileHover={{ scale: 1.05, transition: { type: 'spring', stiffness: 1000 } }} onClick={onClick} ref={ref}>
    <div style={cardStyles} role="group">
      <div className='relative flex justify-center items-center flex-col gap-4 text-center py-0 px-3 h-full w-full overflow-hidden'>
        <div style={iconStyles} >
          <Icon size='fill' className="fill-current" />
        </div>
        <div className='flex justify-center items-center flex-col gap-2'>
          <h3 style={h3Styles} >{title}</h3>
          <p style={pStyles}>{subtitle}</p>
        </div>
      </div>
    </div>
  </motion.div>
})

MagicCard.displayName = 'MagicCard'
