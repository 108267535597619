import { useCallback, useState } from "react"
import { useCallbackRef } from "./useCallbackRef"

export interface UseDisclosureProps<TArgs extends unknown[]> {
    isOpen?: boolean
    defaultIsOpen?: boolean
    onClose?(): void
    onOpen?(...state: TArgs): void
    id?: string
}

export interface UseDisclosureReturn<TArgs extends unknown[]> {
    isOpen: boolean
    onOpen(...state: TArgs): void
    onClose(): void
    onToggle(...state: TArgs): void
    state: TArgs | undefined
}

export function useDisclosure<TArgs extends unknown[]>(props: UseDisclosureProps<TArgs> = {}): UseDisclosureReturn<TArgs> {
    const {
        onClose: onCloseProp,
        onOpen: onOpenProp,
        isOpen: isOpenProp,
    } = props

    const handleOpen = useCallbackRef(onOpenProp)
    const handleClose = useCallbackRef(onCloseProp)
    const [state, setState] = useState<TArgs | undefined>(undefined);

    const [isOpenState, setIsOpen] = useState(props.defaultIsOpen || false)

    const isOpen = isOpenProp !== undefined ? isOpenProp : isOpenState

    const isControlled = isOpenProp !== undefined

    const onClose = useCallback(() => {
        if (!isControlled) {
            setState(undefined)
            setIsOpen(false)
        }
        handleClose?.()
    }, [isControlled, handleClose])

    const onOpen = useCallback((...args: TArgs) => {
        if (!isControlled) {
            setIsOpen(true)
        }
        setState(args)
        handleOpen?.(...args)
    }, [isControlled, handleOpen])

    const onToggle = useCallback((...args: TArgs) => {
        if (isOpen) {
            setState(undefined)
            onClose()
        } else {
            setState(args)
            onOpen(...args)
        }
    }, [isOpen, onOpen, onClose])

    return {
        isOpen,
        onOpen,
        onClose,
        onToggle,
        state
    }
}