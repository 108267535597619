import clsx from "clsx";
import { ComponentType, FC, SVGProps, forwardRef } from "react"


interface IconProps {
    icon: ComponentType<SVGProps<SVGSVGElement>>
    size?: "extraSmall" | "small" | "medium" | "large" | "fill";
    className?: string;
}

export type SvgIconProps = Omit<IconProps, 'icon'>

export const IconBase: FC<IconProps> = forwardRef<SVGSVGElement, IconProps>(({ icon: Icon, size = "medium", className, ...props }, ref) => {
    return <Icon ref={ref} {...props} className={clsx("fill-current", className, {
        "w-3 h-3": size === "extraSmall",
        "w-4 h-4": size === "small",
        "w-5 h-5": size === "medium",
        "w-6 h-6": size === "large",
        "w-full h-full": size === "fill",
    })} />
})