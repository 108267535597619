import clsx from 'clsx'
import { motion } from 'framer-motion'
import { useDisclosure } from 'hooks/useDisclosure'
import { useEffect, useState } from 'react'
import { JeanIcon } from 'ui/icon/icons/Jean'
import { KebabIcon } from 'ui/icon/icons/Kebab'

function useScreenCenter(element: Element | null) {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    if (element == null) return
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), {
      rootMargin: '-50% 0% -50% 0%',
      threshold: 0
    })
    observer.observe(element)
    return () => {
      observer.disconnect()
    }
  }, [element])

  return isIntersecting
}

function NavMenuItem({ children, href, onClick }: { children: React.ReactNode, href: string, onClick?: () => void }) {
  const [element, setElement] = useState<HTMLElement | null>(null)
  useEffect(() => {
    const e = document.getElementById(href.replace('#', ''))
    setElement(e)
  }, [href])

  const isCenter = useScreenCenter(element)
  return (
    <motion.a onClick={onClick} className={clsx('m-2 cursor-pointer', { "text-[#8d3677]": isCenter, "text-black ": !isCenter })} whileHover={{ y: -1 }} whileTap={{ scale: 0.98 }} href={href} >
      {children}
    </motion.a >
  )
}

export const NavMenu = () => {
  const { isOpen, onToggle, onClose } = useDisclosure()

  return (<>
    <div className='md:hidden absolute top-0 left-0 m-2 h-20 z-20'>
      <JeanIcon className='h-full w-auto block p-4 text-[#8d3677] ' />
    </div>
    <motion.button className='md:hidden fixed m-8 right-0 top-0 border-full z-100' onClick={onToggle} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
      <KebabIcon className='h-full w-auto block text-black' />
    </motion.button>
    <motion.nav
      className='fixed top-0 right-0 z-90 flex md:hidden p-8 gap-6 w-[300px] h-screen flex-col bg-white shadow-xl'
      initial="closed"
      variants={{
        open: { opacity: 1, x: 0 },
        closed: { opacity: 0, x: '100%' }
      }}
      animate={isOpen ? 'open' : 'closed'}
    >
      <NavMenuItem href="#why_jean" onClick={onClose}>Why Jean?</NavMenuItem>
      <NavMenuItem href="#products" onClick={onClose}>Products</NavMenuItem>
      <NavMenuItem href="#how_it_works" onClick={onClose}>How it works</NavMenuItem>
      <NavMenuItem href="#smart_checkout" onClick={onClose}>Smart payment</NavMenuItem>
      <span><a href='https://www.facebook.com/people/JEAN/100086443823425/' target="_blank"
        className='bg-[#8d3677] text-white border-md rounded-lg p-3 font-bold hover:bg-[#bb4c9f]'>Contact us</a></span>
    </motion.nav>
    <div className='shadow-[0px_8px_22px_4px_rgba(0,0,0,0.05)] whitespace-nowrap w-full sticky top-0 h-20 px-20 max-md:hidden md:flex items-center bg-[#F7F6F7] text-[#1e0c1a] font-semibold z-100'>
      <JeanIcon className="h-full w-auto block p-4 text-[#8d3677]" />
      <div className='flex-1' />
      <div className='flex items-center gap-4'>
        <NavMenuItem href="#why_jean">Why Jean?</NavMenuItem>
        <NavMenuItem href="#products">Products</NavMenuItem>
        <NavMenuItem href="#how_it_works">How it works</NavMenuItem>
        <NavMenuItem href="#smart_checkout">Smart payment</NavMenuItem>
        <a href='https://www.facebook.com/people/JEAN/100086443823425/' target="_blank" className='bg-[#8d3677] text-white border-md p-3 rounded-lg font-bold hover:bg-[#bb4c9f]'>Contact us</a>
      </div>
    </div>
  </>)
}
