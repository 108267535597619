import { useNavigate } from "react-router-dom";
import { Button } from "ui/button/Button";

export const ErrorPage = () => {
    const navigate = useNavigate();
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            <h1 className="text-4xl font-bold text-red-500">Oops!</h1>
            <p className="mt-4 text-lg text-gray-700">Something went wrong.</p>
            <Button onClick={() => navigate("../")} variant="secondary" className="mt-4">
                Go back
            </Button>
        </div>
    );
};
