import { GoogleAuthProvider, signInWithPopup, signOut as firebaseSignOut, browserSessionPersistence, setPersistence, User, getAuth, IdTokenResult } from "firebase/auth";
import { auth } from "./firebase";
import i18n from "i18next";
import toast from "react-hot-toast";
import { api } from "./axios";

const provider = new GoogleAuthProvider();
/*
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    scopes: https://developers.google.com/identity/protocols/oauth2/scopes
*/
auth.languageCode = i18n.language;

export const signOut = async () => {
    try {
        await firebaseSignOut(auth)
    } catch (error) {
        console.log(error)
    }
}

export const signInWithGoogle = async () => {
    try {
        setPersistence(auth, browserSessionPersistence)
        const result = await signInWithPopup(auth, provider)
        const user = result.user;
        const { developer, admin, restaurantId } = await getClaims(user);
        if (!developer && !admin && !restaurantId) {
            toast.error("You are not allowed to use admin app, request access from an admin")
            getAuth().signOut()
        }
    } catch (error: any) {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(errorCode, errorMessage, credential)
        toast.error("You are not allowed to use admin app, request access from an admin")
        getAuth().signOut()
    }
}



export async function getClaims(u?: User) {
    const user = u || auth.currentUser;
    let idTokenResult = await user?.getIdTokenResult()
    const customClaims = getCustomClaimsFromTokenResult(idTokenResult)
    console.log("idTokenResult", idTokenResult)
    if (customClaims.restaurantId) {
        return customClaims
    }
    try {
        const result = await requestClaims()
        if (!result) {
            return customClaims
        }
        await user?.reload()
        const reloadedUser = auth.currentUser
        if (!reloadedUser) {
            return customClaims
        }
        idTokenResult = await reloadedUser.getIdTokenResult(true)
        return getCustomClaimsFromTokenResult(idTokenResult)
    } catch (err) {
        console.log(err)
        return customClaims
    }

}

export function setDevRestaurantId(restaurantId: string) {
    localStorage.setItem("dev_restaurantId", restaurantId)
}
function getDevRestaurantId() {
    return localStorage.getItem("dev_restaurantId") || "salve"
}
function getCustomClaimsFromTokenResult(idTokenResult: IdTokenResult | undefined) {
    const developer = idTokenResult?.claims.role === 'developer'
    const admin = idTokenResult?.claims.role === 'admin'
    const restaurantId = developer ? getDevRestaurantId() : (idTokenResult?.claims?.restaurantId as string | undefined);
    return { developer, admin, restaurantId }
}

async function requestClaims() {
    const result = await api.post("requestClaims")
    return result.data;
}