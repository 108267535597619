
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import intervalPlural from 'i18next-intervalplural-postprocessor'
import { initReactI18next } from 'react-i18next'
import dayjs from 'dayjs'
import translationEN from "../assets/locales/en/translation.json";
import translationHU from "../assets/locales/hu/translation.json";

const resources = {
    en: {
        translation: translationEN
    },
    hu: {
        translation: translationHU
    }
};

i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(intervalPlural)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'hu',
        interpolation: {
            escapeValue: false
        },
    })
dayjs.locale(i18next.language)


export { i18next }