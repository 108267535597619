export const container = {
    hidden: { opacity: 0, y: 30 },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            staggerChildren: 0.2
        }
    }
}

export const item = {
    hidden: { opacity: 0, y: 30 },
    show: { opacity: 1, y: 0 }
}