import clsx from 'clsx'
import { motion } from 'framer-motion'
import { type PropsWithChildren, type ReactNode } from 'react'
import { Image } from 'ui/image/Image'
import { Subtitle } from 'ui/text/Subtitle'
import { Text } from 'ui/text/Text'
import { Title } from 'ui/text/Title'

interface SectionProps {
  title: string
  subtitle?: string
  action?: ReactNode
  imageSrc?: string
  imagePosition?: 'left' | 'right'
  imageBorder?: boolean
  otherComponent?: ReactNode
  gap?: number
  titleSize?: string;
  align?: 'flex-start' | 'center' | 'flex-end'
}
export function Section({ children, title, subtitle, action, imageSrc, gap, imagePosition = 'right', imageBorder = false, otherComponent, align = 'center' }: PropsWithChildren<SectionProps>) {
  const image = imageSrc
    ? <Image
      src={imageSrc}
      className={clsx('max-w-[min(460px,65vw)] max-h-[560px] min-w-[260px] object-cover rounded-lg object-bottom m-auto',
        {
          'rounded-lg border-gray-300': imageBorder,
          'rounded-none': !imageBorder,
        })}
    />
    : otherComponent

  return (
    <div className={clsx('m-auto py-8 md:py-16 px-4 flex items-center max-w-[1110px]', {
      'flex-wrap': imagePosition === 'left',
      'flex-wrap-reverse': imagePosition === 'right',
      "justify-center": align === "center",
      "gap-4": !gap,
    })}
    >
      {imagePosition === 'left' && <div className='flex-1' >{image} </div>}
      <div className='flex flex-col gap-6 max-w-[500px] mb-12'>
        <motion.span initial={{ y: 20, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{ ease: 'easeOut', duration: 0.6 }}>
          <Title fontWeight="font-semibold" className={clsx("text-3xl text-gray-800", {
            "pl-6": align === "flex-start",
          })}>{title}</Title>
        </motion.span>
        {!!subtitle && <motion.span initial={{ x: 20, opacity: '0' }} whileInView={{ x: 0, opacity: 1 }} transition={{ ease: 'easeOut', duration: 0.2, delay: 0.4 }}>
          <Subtitle className={clsx({
            "pl-6": align === "flex-start",
          })}>{subtitle}</Subtitle>
        </motion.span>}
        <motion.span initial={{ x: 20, opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} transition={{ ease: 'easeOut', duration: 0.2, delay: 0.4 }}>
          <Text className={clsx(
            'text-gray-700',
            {
              "pl-6": align === "flex-start",
            }
          )} >
            {children}
          </Text>
        </motion.span>
        {action}
      </div>
      {imagePosition === 'right' && <div className='flex-1'>{image}</div>}
    </div>
  )
}
