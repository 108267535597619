/*import { useEffect, useRef, useState } from 'react'*/

export function JeanVideo() {
  /*
  const [isPlaying, setIsPlaying] = useState(true)
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (!isPlaying) {
      console.log(isPlaying, videoRef.current)
      videoRef.current?.play()
    }
  }, [isPlaying])
*/
  return (
    <div className='z-10 max-h-[560px] max-w-[500px] min-w-[300px] relative m-auto'>
      {/*isPlaying &&*/ <video autoPlay muted /*onPause={() => setIsPlaying(false)}*/ style={{ zIndex: 2, position: 'relative' }}>
        <source src={'/demo.mp4'} type="video/mp4" style={{ zIndex: 1 }} />
      </video>}
      {/*<video ref={videoRef} muted loop style={{ zIndex: 1, position: isPlaying ? 'absolute' : 'initial', top: 0 }}>
        <source src='/after.mp4' type="video/mp4" style={{ zIndex: 1 }} />
  </video>*/}
    </div>
  )
}
