import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { CaptureConsole, ExtraErrorData, HttpClient } from "@sentry/integrations";
import { ConsoleMock } from "./ConsoleMock";

export function initSentry() {
    if (process.env.NODE_ENV === 'production') {
        if (!localStorage.getItem("logThings")) {
            window.console = new ConsoleMock()
        }
        Sentry.init({
            environment: import.meta.env.VITE_APP_ENVIRONMENT,
            dsn: "https://85f4a49be33041c2853a2878d3b024c4@o1271215.ingest.sentry.io/6463149",
            integrations: [
                new Sentry.BrowserTracing({
                    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                        useEffect,
                        useLocation,
                        useNavigationType,
                        createRoutesFromChildren,
                        matchRoutes
                    ),
                }),
                new Sentry.Replay({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
                new CaptureConsole({ levels: ['error'] }),
                new ExtraErrorData(),
                new HttpClient()
            ],
            // Performance Monitoring
            tracesSampleRate: 0.05, // Capture 100% of the transactions
            // Session Replay
            replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }
}




