import { getFixedT } from "i18next";
import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "shared/components/checkbox/Checkbox";
import { Button } from "ui/button/Button";
import { GoogleIcon } from "ui/icon/icons/Google";
import { signInWithGoogle } from "utils/auth";

export function Login() {
    const isChecked = useRef(false)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const t = getFixedT('hu')
    const login = async () => {
        if (!isChecked.current) {
            toast.error(t("guest.home.error.aszf"))
            return;
        }
        try {
            setLoading(true);
            await signInWithGoogle()
            console.log("going to admin page")
            navigate('/admin')
        } catch (err) {
            err instanceof Error && toast.error(err.message)
            setLoading(false);
        }
    }

    return <div className="flex flex-col items-center justify-center h-screen">
        <div className="flex justify-center m-4 items-center" >
            <Checkbox onChange={e => isChecked.current = e.target.checked} label={t('guest.home.aszf')} />
            <a href="/aszfv1.0.docx" target="_blank" className="text-primary-500 ml-1">{t("guest.home.aszfLink")}</a>
        </div>
        <Button onClick={login} loading={loading} className="flex items-center justify-center gap-2" variant="tertiary"><GoogleIcon className="w-5 h-5" />Bejelentkezés google-el</Button>
    </div>
}