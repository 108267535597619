import clsx from "clsx";
import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import { LoadingSpinner } from "ui/LoadingSpinner";

export interface ButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    variant?: "primary" | "secondary" | "tertiary" | "accept" | "decline" | "outline" | "ghost";
    size?: "extraSmall" | "small" | "medium" | "large";
    loading?: boolean;
}

export function Button({ variant = "primary", size = "medium", className, disabled, loading, children, ...buttonProps }: ButtonProps) {

    const gap = clsx({ "gap-2": size !== "extraSmall", "gap-0.5": size === "extraSmall" })
    const classes = clsx(className, gap,
        "font-semibold transition-colors duration-100 ease-in-out rounded-2xl flex items-center relative",
        {
            "cursor-not-allowed opacity-60": disabled || loading,
            "bg-secondary-400  hover:bg-secondary-500 border border-secondary-400": variant === "secondary",
            "bg-primary-400 hover:bg-primary-500 border border-primary-400 text-primary-950": variant === "primary",
            "bg-green-500  hover:bg-green-600 hover:border-green-600 border border-green-500": variant === "accept",
            "bg-red-500  hover:bg-red-600 hover:border-red-600  border border-red-500": variant === "decline",
            "bg-gray-200 hover:bg-gray-300 text-gray-900 border border-gray-200": variant === "tertiary",
            "hover:bg-gray-200 text-gray-900 border border-transparent hover:border-gray-200": variant === "ghost",
            "border border-primary-400 hover:bg-primary-50 text-primary-400": variant === "outline",
            "text-white": !["tertiary", "ghost", "outline"].includes(variant),
            "text-xs py-1 px-1.5": size === "extraSmall",
            "text-sm py-1 px-3": size === "small",
            "text-sm py-1.5 px-3": size === "medium",
            "text-md py-2 px-5 rounded-full": size === "large",
        },

    )

    return <button {...buttonProps} className={classes} disabled={disabled || loading} >
        <span className={clsx("flex items-center", gap, { "opacity-0": loading })}>{children}</span>
        {loading && <span className="absolute left-1/2 -translate-x-1/2"><LoadingSpinner /></span>}
    </button>
}