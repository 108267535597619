import { WebRootPage } from 'pages/web/WebRootPage';
import { TestPage } from 'pages/TestPage';
import {
    createBrowserRouter,
    LoaderFunctionArgs,
    Navigate,
} from "react-router-dom";
import { Login } from 'pages/LoginPage';
import { ErrorPage } from 'pages/ErrorPage';
import * as Sentry from "@sentry/react";

const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
    ...(import.meta.env.DEV ? [{
        path: "/test",
        element: <TestPage />,
        errorElement: <ErrorPage />
    }] : []),
    {
        path: "/",
        element: <WebRootPage />,
        errorElement: <ErrorPage />
    },
    {
        path: "/login",
        element: <Login />,
        errorElement: <ErrorPage />
    },
    {
        path: "/admin",
        lazy: () => import("./pages/admin/AdminRootPage"),
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <Navigate to="dashboard" />,
                errorElement: <ErrorPage />
            },
            {
                lazy: () => import("./pages/admin/AdminDashboardPage"),
                path: "dashboard",
                errorElement: <ErrorPage />
            },
            {
                lazy: () => import("./pages/admin/AdminOrdersPage"),
                path: "orders",
                errorElement: <ErrorPage />
            },
            {
                lazy: () => import("./pages/admin/AdminMenuPage"),
                path: "menu",
                errorElement: <ErrorPage />
            },
            {
                lazy: () => import("./pages/admin/AdminImagesPage"),
                path: "images",
                errorElement: <ErrorPage />
            },
            {
                lazy: () => import("./pages/admin/AdminSettingsPage"),
                path: "settings",
                errorElement: <ErrorPage />
            },
        ]
    },
    {
        path: "/guest",
        loader: async ({ request, params }: LoaderFunctionArgs) => {
            const { loader } = await import("./pages/guest/guestRootLoader");
            return loader({ request, params });
        },
        lazy: () => import("./pages/guest/GuestRootPage"),
        errorElement: <ErrorPage />,
        children: [
            {
                lazy: () => import("./pages/guest/GuestLandingPage"),
                path: "",
                errorElement: <ErrorPage />
            },
            {
                lazy: () => import("./pages/guest/GuestMenuPage"),
                loader: async ({ request, params }: LoaderFunctionArgs) => {
                    const { loader } = await import("./pages/guest/guestMenuLoader");
                    return loader({ request, params });
                },
                path: "menu",
                errorElement: <ErrorPage />
            },
            {
                element: <>Terms and conditions</>,
                path: "terms-and-conditions",
                errorElement: <ErrorPage />
            },
        ]
    },
    {
        path: 's/:scanId',
        loader: async ({ request, params }: LoaderFunctionArgs) => {
            const { loader } = await import("./pages/tableShortScannedLoader");
            return loader({ request, params });
        },
        lazy: () => import("./pages/TableShortScannedPage"),
        errorElement: <ErrorPage />
    },
    {
        path: 'table-scanned',
        loader: async ({ request, params }: LoaderFunctionArgs) => {
            const { loader } = await import("./pages/tableScannedLoader");
            return loader({ request, params });
        },
        lazy: () => import("./pages/TableScannedPage"),
        errorElement: <ErrorPage />
    },
    {
        path: 'scan-failed',
        lazy: () => import("./pages/ScanFailedPage"),
        errorElement: <ErrorPage />
    },
    {
        path: "/*",
        element: <Navigate to="/" />,
    },
]);