import { router } from 'router';
import { LoadingScreen } from 'pages/LoadingScreen';
import {
    RouterProvider,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Toaster } from 'react-hot-toast';
import { I18nextProvider } from 'react-i18next';
import { i18next } from 'utils/initLang';


const App = () => <>
    <I18nextProvider i18n={i18next}>
        <RouterProvider router={router} fallbackElement={<LoadingScreen />} />
        <Toaster />
    </I18nextProvider>
</>
export const Application = Sentry.withProfiler(App)