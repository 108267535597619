import clsx from "clsx";
import { forwardRef, useId } from "react";

export interface CheckboxProps extends React.ComponentProps<"input"> {
    label?: string;
    className?: string;
    disabled?: boolean;
    inputRef?: React.Ref<HTMLInputElement>;
    rootClassName?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
    (
        {
            label,
            className,
            disabled,
            inputRef,
            rootClassName,
            ...rest
        },
        ref,
    ) => {
        const id = useId();
        const rootClasses = clsx("inline-flex items-center", {
            "opacity-50 pointer-events-none": disabled,
        }, rootClassName)
        const containerClasses = clsx("relative flex items-center cursor-pointer p-1 rounded-full")
        const inputClasses = clsx(
            "peer relative appearance-none w-4 h-4 border rounded-sm border-primary-200 cursor-pointer transition-all",
            "before:content[''] before:block before:bg-primary-500 before:w-7 before:h-8 before:rounded-full before:absolute before:top-2/4",
            "before:left-2/4 before:-translate-y-2/4 before:-translate-x-2/4",
            "before:opacity-0 hover:before:opacity-10 before:transition-opacity",
            "checked:bg-primary-500 checked:border-primary-500 checked:before:bg-primary-500", className)
        const iconContainerClasses = clsx("text-white absolute top-2/4 left-2/4 transform -translate-x-2/4 -translate-y-2/4 pointer-events-none",
            "opactiy-0 transition-opacity peer-checked:before:opacity-100 peer-checked:hover:opacity-100")
        const labelClasses = clsx("text-primary-950 font-regular ml-2 select-none cursor-pointer mt-px")

        return (
            <div ref={ref} className={rootClasses}>
                <label

                    className={containerClasses}
                    htmlFor={rest.id || id}
                >
                    <input
                        {...rest}
                        ref={inputRef}
                        type="checkbox"
                        disabled={disabled}
                        className={inputClasses}
                        id={rest.id || id}
                    />
                    <span className={iconContainerClasses}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-3.5 w-3.5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            stroke="currentColor"
                            strokeWidth={1}
                        >
                            <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </span>
                </label>
                {label && (
                    <label className={labelClasses} htmlFor={rest.id || id}>
                        {label}
                    </label>
                )}
            </div>
        );
    },
);