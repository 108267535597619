import axios from "axios";
import { getAuth } from "firebase/auth";
import i18next from "i18next";

export const api = axios.create({
    baseURL: import.meta.env.VITE_APP_API_URL,
});

api.interceptors.request.use(async function (config) {
    const token = await getAuth().currentUser?.getIdToken();
    if (token) {
        config.headers['authorization'] = `Bearer ${token}`
    }
    if (i18next.language) {
        config.headers['X-language'] = i18next.language
    }
    return config;
});